body {
    background: #c2aec5;
    color: white;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Top-controls {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background-color: #f7f7f7;
    padding: 8px;
    justify-content: center;
}

input {
    margin-bottom: 200px;
}

.common__dialogTitle_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.fileInput-style{
    width: 500px;
}
div.fileInput-style p{
    margin-bottom: 0px;
}
.fileInput-style div[data-testid="dropzone"]{
    height: 50px;
    background-color: #F6F6F6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}